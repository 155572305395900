import * as $ from 'jquery';


const popupCookies = {};
popupCookies.setcookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

popupCookies.getcookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

popupCookies.checkcookie = () => {
  var user = popupCookies.getcookie("farmercookies");

  if (user != "") {
    
  } else {
    $('#popup-cookies').fadeIn(300);
    $('body').addClass('lock'); 
    popupCookies.setcookie("farmercookies", "farmer", 1);
  }   
}

popupCookies.cookieshome = () => {

  $('#popup-cookies .close').on('click',function(e){
        e.preventDefault()
        $('#popup-cookies').fadeOut(300);
      	$('body').removeClass('lock');  
  });
}

window.onload = () => {
    popupCookies.checkcookie()
    popupCookies.cookieshome()
}


